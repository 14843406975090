import { default as indexuFd73U2ASkMeta } from "/var/www/cld-nuxt/pages/index.vue?macro=true";
import { default as _91_46_46_46404_93O6SHdx3ew3Meta } from "/var/www/cld-nuxt/pages/[...404].vue?macro=true";
import { default as cartXSpkp1oWXRMeta } from "/var/www/cld-nuxt/pages/account/cart.vue?macro=true";
import { default as checkoutkdpRhwlCZbMeta } from "/var/www/cld-nuxt/pages/account/checkout.vue?macro=true";
import { default as contact6qlsUn8EtqMeta } from "/var/www/cld-nuxt/pages/account/contact.vue?macro=true";
import { default as dashboardHY4rQ5mE4NMeta } from "/var/www/cld-nuxt/pages/account/dashboard.vue?macro=true";
import { default as indexRY79F24zJNMeta } from "/var/www/cld-nuxt/pages/account/forgot-password/[id]/index.vue?macro=true";
import { default as indexBIDRoYXSudMeta } from "/var/www/cld-nuxt/pages/account/forgot-password/index.vue?macro=true";
import { default as loginQNh99Wqmp5Meta } from "/var/www/cld-nuxt/pages/account/login.vue?macro=true";
import { default as _91id_93bfizjktZ8jMeta } from "/var/www/cld-nuxt/pages/account/post-signup/[id].vue?macro=true";
import { default as pre_45signupaggGO7GAW7Meta } from "/var/www/cld-nuxt/pages/account/pre-signup.vue?macro=true";
import { default as profileBmB4cXzUO2Meta } from "/var/www/cld-nuxt/pages/account/profile.vue?macro=true";
import { default as contact_45usknkkQKvl06Meta } from "/var/www/cld-nuxt/pages/contact-us.vue?macro=true";
import { default as backorders0zDEBLsb9iMeta } from "/var/www/cld-nuxt/pages/order/backorders.vue?macro=true";
import { default as cartmanagementspXydJYAw3Meta } from "/var/www/cld-nuxt/pages/order/cartmanagement.vue?macro=true";
import { default as deliverablesXZYJiHG3UyMeta } from "/var/www/cld-nuxt/pages/order/deliverables.vue?macro=true";
import { default as downloadhistory_46client7VFHRBnCmkMeta } from "/var/www/cld-nuxt/pages/order/downloadhistory.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/var/www/cld-nuxt/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as historyvE7BKpK6f9Meta } from "/var/www/cld-nuxt/pages/order/history.vue?macro=true";
import { default as opendocsmQRTPOTPrAMeta } from "/var/www/cld-nuxt/pages/order/opendocs.vue?macro=true";
import { default as searchhistoryeUKYhBYQa8Meta } from "/var/www/cld-nuxt/pages/order/searchhistory.vue?macro=true";
import { default as unavailableDeliverieshqa9pP1kHzMeta } from "/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue?macro=true";
import { default as our_45historyLpkNhO4sryMeta } from "/var/www/cld-nuxt/pages/our-history.vue?macro=true";
import { default as our_45servicesqaHplgYL2XMeta } from "/var/www/cld-nuxt/pages/our-services.vue?macro=true";
import { default as authDsFgbrruKWMeta } from "/var/www/cld-nuxt/pages/page/auth/auth.js?macro=true";
import { default as pricedown_46clientMlTtunD5coMeta } from "/var/www/cld-nuxt/pages/pricedown.client.vue?macro=true";
import { default as _91name_93EFzugGY4sNMeta } from "/var/www/cld-nuxt/pages/products/[id]/[name].vue?macro=true";
import { default as searchhM5BoR937ZMeta } from "/var/www/cld-nuxt/pages/products/search.vue?macro=true";
import { default as profileDdarnLnGyKMeta } from "/var/www/cld-nuxt/pages/user/profile.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/cld-nuxt/pages/index.vue")
  },
  {
    name: "404___fr",
    path: "/fr/:404(.*)*",
    component: () => import("/var/www/cld-nuxt/pages/[...404].vue")
  },
  {
    name: "404___en",
    path: "/en/:404(.*)*",
    component: () => import("/var/www/cld-nuxt/pages/[...404].vue")
  },
  {
    name: "404___de",
    path: "/de/:404(.*)*",
    component: () => import("/var/www/cld-nuxt/pages/[...404].vue")
  },
  {
    name: "404___nl",
    path: "/nl/:404(.*)*",
    component: () => import("/var/www/cld-nuxt/pages/[...404].vue")
  },
  {
    name: "404___es",
    path: "/es/:404(.*)*",
    component: () => import("/var/www/cld-nuxt/pages/[...404].vue")
  },
  {
    name: "404___sv",
    path: "/sv/:404(.*)*",
    component: () => import("/var/www/cld-nuxt/pages/[...404].vue")
  },
  {
    name: "account-cart___fr",
    path: "/fr/account/cart",
    component: () => import("/var/www/cld-nuxt/pages/account/cart.vue")
  },
  {
    name: "account-cart___en",
    path: "/en/account/cart",
    component: () => import("/var/www/cld-nuxt/pages/account/cart.vue")
  },
  {
    name: "account-cart___de",
    path: "/de/account/cart",
    component: () => import("/var/www/cld-nuxt/pages/account/cart.vue")
  },
  {
    name: "account-cart___nl",
    path: "/nl/account/cart",
    component: () => import("/var/www/cld-nuxt/pages/account/cart.vue")
  },
  {
    name: "account-cart___es",
    path: "/es/account/cart",
    component: () => import("/var/www/cld-nuxt/pages/account/cart.vue")
  },
  {
    name: "account-cart___sv",
    path: "/sv/account/cart",
    component: () => import("/var/www/cld-nuxt/pages/account/cart.vue")
  },
  {
    name: "account-checkout___fr",
    path: "/fr/account/checkout",
    component: () => import("/var/www/cld-nuxt/pages/account/checkout.vue")
  },
  {
    name: "account-checkout___en",
    path: "/en/account/checkout",
    component: () => import("/var/www/cld-nuxt/pages/account/checkout.vue")
  },
  {
    name: "account-checkout___de",
    path: "/de/account/checkout",
    component: () => import("/var/www/cld-nuxt/pages/account/checkout.vue")
  },
  {
    name: "account-checkout___nl",
    path: "/nl/account/checkout",
    component: () => import("/var/www/cld-nuxt/pages/account/checkout.vue")
  },
  {
    name: "account-checkout___es",
    path: "/es/account/checkout",
    component: () => import("/var/www/cld-nuxt/pages/account/checkout.vue")
  },
  {
    name: "account-checkout___sv",
    path: "/sv/account/checkout",
    component: () => import("/var/www/cld-nuxt/pages/account/checkout.vue")
  },
  {
    name: "account-contact___fr",
    path: "/fr/account/contact",
    component: () => import("/var/www/cld-nuxt/pages/account/contact.vue")
  },
  {
    name: "account-contact___en",
    path: "/en/account/contact",
    component: () => import("/var/www/cld-nuxt/pages/account/contact.vue")
  },
  {
    name: "account-contact___de",
    path: "/de/account/contact",
    component: () => import("/var/www/cld-nuxt/pages/account/contact.vue")
  },
  {
    name: "account-contact___nl",
    path: "/nl/account/contact",
    component: () => import("/var/www/cld-nuxt/pages/account/contact.vue")
  },
  {
    name: "account-contact___es",
    path: "/es/account/contact",
    component: () => import("/var/www/cld-nuxt/pages/account/contact.vue")
  },
  {
    name: "account-contact___sv",
    path: "/sv/account/contact",
    component: () => import("/var/www/cld-nuxt/pages/account/contact.vue")
  },
  {
    name: "account-dashboard___fr",
    path: "/fr/account/dashboard",
    component: () => import("/var/www/cld-nuxt/pages/account/dashboard.vue")
  },
  {
    name: "account-dashboard___en",
    path: "/en/account/dashboard",
    component: () => import("/var/www/cld-nuxt/pages/account/dashboard.vue")
  },
  {
    name: "account-dashboard___de",
    path: "/de/account/dashboard",
    component: () => import("/var/www/cld-nuxt/pages/account/dashboard.vue")
  },
  {
    name: "account-dashboard___nl",
    path: "/nl/account/dashboard",
    component: () => import("/var/www/cld-nuxt/pages/account/dashboard.vue")
  },
  {
    name: "account-dashboard___es",
    path: "/es/account/dashboard",
    component: () => import("/var/www/cld-nuxt/pages/account/dashboard.vue")
  },
  {
    name: "account-dashboard___sv",
    path: "/sv/account/dashboard",
    component: () => import("/var/www/cld-nuxt/pages/account/dashboard.vue")
  },
  {
    name: "account-forgot-password-id___fr",
    path: "/fr/account/forgot-password/:id()",
    meta: indexRY79F24zJNMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/[id]/index.vue")
  },
  {
    name: "account-forgot-password-id___en",
    path: "/en/account/forgot-password/:id()",
    meta: indexRY79F24zJNMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/[id]/index.vue")
  },
  {
    name: "account-forgot-password-id___de",
    path: "/de/account/forgot-password/:id()",
    meta: indexRY79F24zJNMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/[id]/index.vue")
  },
  {
    name: "account-forgot-password-id___nl",
    path: "/nl/account/forgot-password/:id()",
    meta: indexRY79F24zJNMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/[id]/index.vue")
  },
  {
    name: "account-forgot-password-id___es",
    path: "/es/account/forgot-password/:id()",
    meta: indexRY79F24zJNMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/[id]/index.vue")
  },
  {
    name: "account-forgot-password-id___sv",
    path: "/sv/account/forgot-password/:id()",
    meta: indexRY79F24zJNMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/[id]/index.vue")
  },
  {
    name: "account-forgot-password___fr",
    path: "/fr/account/forgot-password",
    meta: indexBIDRoYXSudMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/index.vue")
  },
  {
    name: "account-forgot-password___en",
    path: "/en/account/forgot-password",
    meta: indexBIDRoYXSudMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/index.vue")
  },
  {
    name: "account-forgot-password___de",
    path: "/de/account/forgot-password",
    meta: indexBIDRoYXSudMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/index.vue")
  },
  {
    name: "account-forgot-password___nl",
    path: "/nl/account/forgot-password",
    meta: indexBIDRoYXSudMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/index.vue")
  },
  {
    name: "account-forgot-password___es",
    path: "/es/account/forgot-password",
    meta: indexBIDRoYXSudMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/index.vue")
  },
  {
    name: "account-forgot-password___sv",
    path: "/sv/account/forgot-password",
    meta: indexBIDRoYXSudMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/forgot-password/index.vue")
  },
  {
    name: "account-login___fr",
    path: "/fr/account/login",
    meta: loginQNh99Wqmp5Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/login.vue")
  },
  {
    name: "account-login___en",
    path: "/en/account/login",
    meta: loginQNh99Wqmp5Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/login.vue")
  },
  {
    name: "account-login___de",
    path: "/de/account/login",
    meta: loginQNh99Wqmp5Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/login.vue")
  },
  {
    name: "account-login___nl",
    path: "/nl/account/login",
    meta: loginQNh99Wqmp5Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/login.vue")
  },
  {
    name: "account-login___es",
    path: "/es/account/login",
    meta: loginQNh99Wqmp5Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/login.vue")
  },
  {
    name: "account-login___sv",
    path: "/sv/account/login",
    meta: loginQNh99Wqmp5Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/login.vue")
  },
  {
    name: "account-post-signup-id___fr",
    path: "/fr/account/post-signup/:id()",
    meta: _91id_93bfizjktZ8jMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/post-signup/[id].vue")
  },
  {
    name: "account-post-signup-id___en",
    path: "/en/account/post-signup/:id()",
    meta: _91id_93bfizjktZ8jMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/post-signup/[id].vue")
  },
  {
    name: "account-post-signup-id___de",
    path: "/de/account/post-signup/:id()",
    meta: _91id_93bfizjktZ8jMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/post-signup/[id].vue")
  },
  {
    name: "account-post-signup-id___nl",
    path: "/nl/account/post-signup/:id()",
    meta: _91id_93bfizjktZ8jMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/post-signup/[id].vue")
  },
  {
    name: "account-post-signup-id___es",
    path: "/es/account/post-signup/:id()",
    meta: _91id_93bfizjktZ8jMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/post-signup/[id].vue")
  },
  {
    name: "account-post-signup-id___sv",
    path: "/sv/account/post-signup/:id()",
    meta: _91id_93bfizjktZ8jMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/post-signup/[id].vue")
  },
  {
    name: "account-pre-signup___fr",
    path: "/fr/account/pre-signup",
    meta: pre_45signupaggGO7GAW7Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/pre-signup.vue")
  },
  {
    name: "account-pre-signup___en",
    path: "/en/account/pre-signup",
    meta: pre_45signupaggGO7GAW7Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/pre-signup.vue")
  },
  {
    name: "account-pre-signup___de",
    path: "/de/account/pre-signup",
    meta: pre_45signupaggGO7GAW7Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/pre-signup.vue")
  },
  {
    name: "account-pre-signup___nl",
    path: "/nl/account/pre-signup",
    meta: pre_45signupaggGO7GAW7Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/pre-signup.vue")
  },
  {
    name: "account-pre-signup___es",
    path: "/es/account/pre-signup",
    meta: pre_45signupaggGO7GAW7Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/pre-signup.vue")
  },
  {
    name: "account-pre-signup___sv",
    path: "/sv/account/pre-signup",
    meta: pre_45signupaggGO7GAW7Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/account/pre-signup.vue")
  },
  {
    name: "account-profile___fr",
    path: "/fr/account/profile",
    component: () => import("/var/www/cld-nuxt/pages/account/profile.vue")
  },
  {
    name: "account-profile___en",
    path: "/en/account/profile",
    component: () => import("/var/www/cld-nuxt/pages/account/profile.vue")
  },
  {
    name: "account-profile___de",
    path: "/de/account/profile",
    component: () => import("/var/www/cld-nuxt/pages/account/profile.vue")
  },
  {
    name: "account-profile___nl",
    path: "/nl/account/profile",
    component: () => import("/var/www/cld-nuxt/pages/account/profile.vue")
  },
  {
    name: "account-profile___es",
    path: "/es/account/profile",
    component: () => import("/var/www/cld-nuxt/pages/account/profile.vue")
  },
  {
    name: "account-profile___sv",
    path: "/sv/account/profile",
    component: () => import("/var/www/cld-nuxt/pages/account/profile.vue")
  },
  {
    name: "contact-us___fr",
    path: "/fr/contact-us",
    component: () => import("/var/www/cld-nuxt/pages/contact-us.vue")
  },
  {
    name: "contact-us___en",
    path: "/en/contact-us",
    component: () => import("/var/www/cld-nuxt/pages/contact-us.vue")
  },
  {
    name: "contact-us___de",
    path: "/de/contact-us",
    component: () => import("/var/www/cld-nuxt/pages/contact-us.vue")
  },
  {
    name: "contact-us___nl",
    path: "/nl/contact-us",
    component: () => import("/var/www/cld-nuxt/pages/contact-us.vue")
  },
  {
    name: "contact-us___es",
    path: "/es/contact-us",
    component: () => import("/var/www/cld-nuxt/pages/contact-us.vue")
  },
  {
    name: "contact-us___sv",
    path: "/sv/contact-us",
    component: () => import("/var/www/cld-nuxt/pages/contact-us.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/var/www/cld-nuxt/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/var/www/cld-nuxt/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/var/www/cld-nuxt/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/var/www/cld-nuxt/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/var/www/cld-nuxt/pages/index.vue")
  },
  {
    name: "index___sv",
    path: "/sv",
    component: () => import("/var/www/cld-nuxt/pages/index.vue")
  },
  {
    name: "order-backorders___fr",
    path: "/fr/order/backorders",
    meta: backorders0zDEBLsb9iMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/backorders.vue")
  },
  {
    name: "order-backorders___en",
    path: "/en/order/backorders",
    meta: backorders0zDEBLsb9iMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/backorders.vue")
  },
  {
    name: "order-backorders___de",
    path: "/de/order/backorders",
    meta: backorders0zDEBLsb9iMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/backorders.vue")
  },
  {
    name: "order-backorders___nl",
    path: "/nl/order/backorders",
    meta: backorders0zDEBLsb9iMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/backorders.vue")
  },
  {
    name: "order-backorders___es",
    path: "/es/order/backorders",
    meta: backorders0zDEBLsb9iMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/backorders.vue")
  },
  {
    name: "order-backorders___sv",
    path: "/sv/order/backorders",
    meta: backorders0zDEBLsb9iMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/backorders.vue")
  },
  {
    name: "order-cartmanagement___fr",
    path: "/fr/order/cartmanagement",
    meta: cartmanagementspXydJYAw3Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/cartmanagement.vue")
  },
  {
    name: "order-cartmanagement___en",
    path: "/en/order/cartmanagement",
    meta: cartmanagementspXydJYAw3Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/cartmanagement.vue")
  },
  {
    name: "order-cartmanagement___de",
    path: "/de/order/cartmanagement",
    meta: cartmanagementspXydJYAw3Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/cartmanagement.vue")
  },
  {
    name: "order-cartmanagement___nl",
    path: "/nl/order/cartmanagement",
    meta: cartmanagementspXydJYAw3Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/cartmanagement.vue")
  },
  {
    name: "order-cartmanagement___es",
    path: "/es/order/cartmanagement",
    meta: cartmanagementspXydJYAw3Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/cartmanagement.vue")
  },
  {
    name: "order-cartmanagement___sv",
    path: "/sv/order/cartmanagement",
    meta: cartmanagementspXydJYAw3Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/cartmanagement.vue")
  },
  {
    name: "order-deliverables___fr",
    path: "/fr/order/deliverables",
    meta: deliverablesXZYJiHG3UyMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/deliverables.vue")
  },
  {
    name: "order-deliverables___en",
    path: "/en/order/deliverables",
    meta: deliverablesXZYJiHG3UyMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/deliverables.vue")
  },
  {
    name: "order-deliverables___de",
    path: "/de/order/deliverables",
    meta: deliverablesXZYJiHG3UyMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/deliverables.vue")
  },
  {
    name: "order-deliverables___nl",
    path: "/nl/order/deliverables",
    meta: deliverablesXZYJiHG3UyMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/deliverables.vue")
  },
  {
    name: "order-deliverables___es",
    path: "/es/order/deliverables",
    meta: deliverablesXZYJiHG3UyMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/deliverables.vue")
  },
  {
    name: "order-deliverables___sv",
    path: "/sv/order/deliverables",
    meta: deliverablesXZYJiHG3UyMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/deliverables.vue")
  },
  {
    name: "order-downloadhistory___fr",
    path: "/fr/order/downloadhistory",
    meta: downloadhistory_46client7VFHRBnCmkMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/order/downloadhistory.client.vue"))
  },
  {
    name: "order-downloadhistory___en",
    path: "/en/order/downloadhistory",
    meta: downloadhistory_46client7VFHRBnCmkMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/order/downloadhistory.client.vue"))
  },
  {
    name: "order-downloadhistory___de",
    path: "/de/order/downloadhistory",
    meta: downloadhistory_46client7VFHRBnCmkMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/order/downloadhistory.client.vue"))
  },
  {
    name: "order-downloadhistory___nl",
    path: "/nl/order/downloadhistory",
    meta: downloadhistory_46client7VFHRBnCmkMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/order/downloadhistory.client.vue"))
  },
  {
    name: "order-downloadhistory___es",
    path: "/es/order/downloadhistory",
    meta: downloadhistory_46client7VFHRBnCmkMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/order/downloadhistory.client.vue"))
  },
  {
    name: "order-downloadhistory___sv",
    path: "/sv/order/downloadhistory",
    meta: downloadhistory_46client7VFHRBnCmkMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/order/downloadhistory.client.vue"))
  },
  {
    name: "order-history___fr",
    path: "/fr/order/history",
    meta: historyvE7BKpK6f9Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/history.vue")
  },
  {
    name: "order-history___en",
    path: "/en/order/history",
    meta: historyvE7BKpK6f9Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/history.vue")
  },
  {
    name: "order-history___de",
    path: "/de/order/history",
    meta: historyvE7BKpK6f9Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/history.vue")
  },
  {
    name: "order-history___nl",
    path: "/nl/order/history",
    meta: historyvE7BKpK6f9Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/history.vue")
  },
  {
    name: "order-history___es",
    path: "/es/order/history",
    meta: historyvE7BKpK6f9Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/history.vue")
  },
  {
    name: "order-history___sv",
    path: "/sv/order/history",
    meta: historyvE7BKpK6f9Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/history.vue")
  },
  {
    name: "order-opendocs___fr",
    path: "/fr/order/opendocs",
    meta: opendocsmQRTPOTPrAMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/opendocs.vue")
  },
  {
    name: "order-opendocs___en",
    path: "/en/order/opendocs",
    meta: opendocsmQRTPOTPrAMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/opendocs.vue")
  },
  {
    name: "order-opendocs___de",
    path: "/de/order/opendocs",
    meta: opendocsmQRTPOTPrAMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/opendocs.vue")
  },
  {
    name: "order-opendocs___nl",
    path: "/nl/order/opendocs",
    meta: opendocsmQRTPOTPrAMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/opendocs.vue")
  },
  {
    name: "order-opendocs___es",
    path: "/es/order/opendocs",
    meta: opendocsmQRTPOTPrAMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/opendocs.vue")
  },
  {
    name: "order-opendocs___sv",
    path: "/sv/order/opendocs",
    meta: opendocsmQRTPOTPrAMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/opendocs.vue")
  },
  {
    name: "order-searchhistory___fr",
    path: "/fr/order/searchhistory",
    meta: searchhistoryeUKYhBYQa8Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/searchhistory.vue")
  },
  {
    name: "order-searchhistory___en",
    path: "/en/order/searchhistory",
    meta: searchhistoryeUKYhBYQa8Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/searchhistory.vue")
  },
  {
    name: "order-searchhistory___de",
    path: "/de/order/searchhistory",
    meta: searchhistoryeUKYhBYQa8Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/searchhistory.vue")
  },
  {
    name: "order-searchhistory___nl",
    path: "/nl/order/searchhistory",
    meta: searchhistoryeUKYhBYQa8Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/searchhistory.vue")
  },
  {
    name: "order-searchhistory___es",
    path: "/es/order/searchhistory",
    meta: searchhistoryeUKYhBYQa8Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/searchhistory.vue")
  },
  {
    name: "order-searchhistory___sv",
    path: "/sv/order/searchhistory",
    meta: searchhistoryeUKYhBYQa8Meta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/searchhistory.vue")
  },
  {
    name: "order-unavailableDeliveries___fr",
    path: "/fr/order/unavailableDeliveries",
    meta: unavailableDeliverieshqa9pP1kHzMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue")
  },
  {
    name: "order-unavailableDeliveries___en",
    path: "/en/order/unavailableDeliveries",
    meta: unavailableDeliverieshqa9pP1kHzMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue")
  },
  {
    name: "order-unavailableDeliveries___de",
    path: "/de/order/unavailableDeliveries",
    meta: unavailableDeliverieshqa9pP1kHzMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue")
  },
  {
    name: "order-unavailableDeliveries___nl",
    path: "/nl/order/unavailableDeliveries",
    meta: unavailableDeliverieshqa9pP1kHzMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue")
  },
  {
    name: "order-unavailableDeliveries___es",
    path: "/es/order/unavailableDeliveries",
    meta: unavailableDeliverieshqa9pP1kHzMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue")
  },
  {
    name: "order-unavailableDeliveries___sv",
    path: "/sv/order/unavailableDeliveries",
    meta: unavailableDeliverieshqa9pP1kHzMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue")
  },
  {
    name: "our-history___fr",
    path: "/fr/our-history",
    component: () => import("/var/www/cld-nuxt/pages/our-history.vue")
  },
  {
    name: "our-history___en",
    path: "/en/our-history",
    component: () => import("/var/www/cld-nuxt/pages/our-history.vue")
  },
  {
    name: "our-history___de",
    path: "/de/our-history",
    component: () => import("/var/www/cld-nuxt/pages/our-history.vue")
  },
  {
    name: "our-history___nl",
    path: "/nl/our-history",
    component: () => import("/var/www/cld-nuxt/pages/our-history.vue")
  },
  {
    name: "our-history___es",
    path: "/es/our-history",
    component: () => import("/var/www/cld-nuxt/pages/our-history.vue")
  },
  {
    name: "our-history___sv",
    path: "/sv/our-history",
    component: () => import("/var/www/cld-nuxt/pages/our-history.vue")
  },
  {
    name: "our-services___fr",
    path: "/fr/our-services",
    component: () => import("/var/www/cld-nuxt/pages/our-services.vue")
  },
  {
    name: "our-services___en",
    path: "/en/our-services",
    component: () => import("/var/www/cld-nuxt/pages/our-services.vue")
  },
  {
    name: "our-services___de",
    path: "/de/our-services",
    component: () => import("/var/www/cld-nuxt/pages/our-services.vue")
  },
  {
    name: "our-services___nl",
    path: "/nl/our-services",
    component: () => import("/var/www/cld-nuxt/pages/our-services.vue")
  },
  {
    name: "our-services___es",
    path: "/es/our-services",
    component: () => import("/var/www/cld-nuxt/pages/our-services.vue")
  },
  {
    name: "our-services___sv",
    path: "/sv/our-services",
    component: () => import("/var/www/cld-nuxt/pages/our-services.vue")
  },
  {
    name: "page-auth-auth___fr",
    path: "/fr/page/auth/auth",
    component: () => import("/var/www/cld-nuxt/pages/page/auth/auth.js")
  },
  {
    name: "page-auth-auth___en",
    path: "/en/page/auth/auth",
    component: () => import("/var/www/cld-nuxt/pages/page/auth/auth.js")
  },
  {
    name: "page-auth-auth___de",
    path: "/de/page/auth/auth",
    component: () => import("/var/www/cld-nuxt/pages/page/auth/auth.js")
  },
  {
    name: "page-auth-auth___nl",
    path: "/nl/page/auth/auth",
    component: () => import("/var/www/cld-nuxt/pages/page/auth/auth.js")
  },
  {
    name: "page-auth-auth___es",
    path: "/es/page/auth/auth",
    component: () => import("/var/www/cld-nuxt/pages/page/auth/auth.js")
  },
  {
    name: "page-auth-auth___sv",
    path: "/sv/page/auth/auth",
    component: () => import("/var/www/cld-nuxt/pages/page/auth/auth.js")
  },
  {
    name: "pricedown___fr",
    path: "/fr/pricedown",
    meta: pricedown_46clientMlTtunD5coMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/pricedown.client.vue"))
  },
  {
    name: "pricedown___en",
    path: "/en/pricedown",
    meta: pricedown_46clientMlTtunD5coMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/pricedown.client.vue"))
  },
  {
    name: "pricedown___de",
    path: "/de/pricedown",
    meta: pricedown_46clientMlTtunD5coMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/pricedown.client.vue"))
  },
  {
    name: "pricedown___nl",
    path: "/nl/pricedown",
    meta: pricedown_46clientMlTtunD5coMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/pricedown.client.vue"))
  },
  {
    name: "pricedown___es",
    path: "/es/pricedown",
    meta: pricedown_46clientMlTtunD5coMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/pricedown.client.vue"))
  },
  {
    name: "pricedown___sv",
    path: "/sv/pricedown",
    meta: pricedown_46clientMlTtunD5coMeta || {},
    component: () => createClientPage(() => import("/var/www/cld-nuxt/pages/pricedown.client.vue"))
  },
  {
    name: "products-id-name___fr",
    path: "/fr/products/:id()/:name()",
    component: () => import("/var/www/cld-nuxt/pages/products/[id]/[name].vue")
  },
  {
    name: "products-id-name___en",
    path: "/en/products/:id()/:name()",
    component: () => import("/var/www/cld-nuxt/pages/products/[id]/[name].vue")
  },
  {
    name: "products-id-name___de",
    path: "/de/products/:id()/:name()",
    component: () => import("/var/www/cld-nuxt/pages/products/[id]/[name].vue")
  },
  {
    name: "products-id-name___nl",
    path: "/nl/products/:id()/:name()",
    component: () => import("/var/www/cld-nuxt/pages/products/[id]/[name].vue")
  },
  {
    name: "products-id-name___es",
    path: "/es/products/:id()/:name()",
    component: () => import("/var/www/cld-nuxt/pages/products/[id]/[name].vue")
  },
  {
    name: "products-id-name___sv",
    path: "/sv/products/:id()/:name()",
    component: () => import("/var/www/cld-nuxt/pages/products/[id]/[name].vue")
  },
  {
    name: "products-search___fr",
    path: "/fr/products/search",
    meta: searchhM5BoR937ZMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/products/search.vue")
  },
  {
    name: "products-search___en",
    path: "/en/products/search",
    meta: searchhM5BoR937ZMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/products/search.vue")
  },
  {
    name: "products-search___de",
    path: "/de/products/search",
    meta: searchhM5BoR937ZMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/products/search.vue")
  },
  {
    name: "products-search___nl",
    path: "/nl/products/search",
    meta: searchhM5BoR937ZMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/products/search.vue")
  },
  {
    name: "products-search___es",
    path: "/es/products/search",
    meta: searchhM5BoR937ZMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/products/search.vue")
  },
  {
    name: "products-search___sv",
    path: "/sv/products/search",
    meta: searchhM5BoR937ZMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/products/search.vue")
  },
  {
    name: "user-profile___fr",
    path: "/fr/user/profile",
    meta: profileDdarnLnGyKMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/user/profile.vue")
  },
  {
    name: "user-profile___en",
    path: "/en/user/profile",
    meta: profileDdarnLnGyKMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/user/profile.vue")
  },
  {
    name: "user-profile___de",
    path: "/de/user/profile",
    meta: profileDdarnLnGyKMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/user/profile.vue")
  },
  {
    name: "user-profile___nl",
    path: "/nl/user/profile",
    meta: profileDdarnLnGyKMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/user/profile.vue")
  },
  {
    name: "user-profile___es",
    path: "/es/user/profile",
    meta: profileDdarnLnGyKMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/user/profile.vue")
  },
  {
    name: "user-profile___sv",
    path: "/sv/user/profile",
    meta: profileDdarnLnGyKMeta || {},
    component: () => import("/var/www/cld-nuxt/pages/user/profile.vue")
  }
]